/* eslint-disable react-hooks/exhaustive-deps */
"use client";

import type { CollectionResponse, ItemResponse, PropertyGroupResponse } from "@/data/__generated__/types/generated";
import type { SelectedFilters } from "@/types";

import { useState } from "react";

import { initialFilters } from "@/constants";
import useFilterQueryParams from "@/hooks/useFilters";
import useCollectionProductsTabs from "@/hooks/useTabs";
import { TabsType } from "@/types";

import View from "./View";
import { useListApi } from "./hooks/useListApi";

type ItemCollectionListProps = {
	categoryDescription?: null | string;
	categoryLft: number;
	categoryRgt: number;
	categorySlug: string;
	categoryTitle?: string;
	listType?: TabsType;
	queryLimit?: number;
	ssrCategoryFilters?: PropertyGroupResponse[];
	ssrCollections: CollectionResponse[];
	ssrProducts: ItemResponse[];
	ssrSelectedFilters: SelectedFilters | null;
};

const ItemCollectionList: React.FC<ItemCollectionListProps> = ({
	listType,
	categoryTitle,
	categorySlug,
	ssrSelectedFilters,
	categoryDescription,
	ssrCollections,
	ssrProducts,
	categoryLft,
	categoryRgt,
	queryLimit,
	ssrCategoryFilters,
}) => {
	const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

	const [defaultFilters, selectedFilters, updateFilter, resetFilters] = useFilterQueryParams(initialFilters);

	const { handleTabChange, queryParams } = useCollectionProductsTabs();
	const { type } = queryParams;
	const isProducts = type === TabsType.products || listType === TabsType.products;

	const {
		collectionsData,
		collectionsLoading,
		productsData,
		productsLoading,
		handleFetchMore,
		// filtersData,
		// filtersLoading,
	} = useListApi({
		categoryUrl: categorySlug,
		isProducts,
		categoryLft,
		categoryRgt,
		selectedFilters,
		ssrSelectedFilters,
		queryLimit,
	});

	const collections = collectionsData?.collections.collections || ssrCollections;
	const products = productsData?.items.items || ssrProducts;
	const hasNextPage = isProducts
		? Boolean(productsData?.items.nextPage)
		: Boolean(collectionsData?.collections.nextPage);

	return (
		<View
			categoryTitle={categoryTitle || ""}
			collections={collections}
			collectionsLoading={collectionsLoading}
			handleTabChange={handleTabChange}
			isProducts={isProducts}
			listType={(type as TabsType) || listType}
			products={products as ItemResponse[]}
			productsLoading={productsLoading}
			handleFetchMore={handleFetchMore}
			filters={ssrCategoryFilters}
			filtersLoading={false}
			handleSelectFilter={updateFilter}
			selectedFilters={defaultFilters}
			resetFilters={resetFilters}
			categoryDescription={categoryDescription}
			mobileFiltersOpen={mobileFiltersOpen}
			closeMobileFilters={() => setMobileFiltersOpen(false)}
			openMobileFilters={() => setMobileFiltersOpen(true)}
			hasNextPage={hasNextPage}
			itemsPerPage={12}
		/>
	);
};

export default ItemCollectionList;
