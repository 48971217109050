import type { CollectionResponse } from "@/data/__generated__/types/generated";

import { PAGINATION_ITEMS_PER_PAGE } from "@/constants";
import CollectionCard from "@/presentation/components/CollectionCard";
import ListNotFound from "@/presentation/components/ui/ListNotFound";
import SkeletonCard from "@/presentation/components/ui/SkeletonCard";

type CollectionsListProps = {
	collections?: CollectionResponse[];
	isLoading?: boolean;
	itemsPerPage?: number;
};

const CollectionsList: React.FC<CollectionsListProps> = ({ collections, isLoading, itemsPerPage }) => {
	if (collections?.length === 0 && !isLoading) {
		return <ListNotFound />;
	}

	return (
		<div className="grid grid-cols-3 gap-6 sm:grid-cols-2 sm:gap-4">
			{isLoading && collections?.length === 0
				? Array.from({ length: itemsPerPage || PAGINATION_ITEMS_PER_PAGE }, (_, index) => <SkeletonCard key={index} />)
				: collections?.map((collection) => <CollectionCard key={collection?.id} collection={collection} />)}
		</div>
	);
};

export default CollectionsList;
