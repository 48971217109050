import type { ItemResponse } from "@/data/__generated__/types/generated";

import { PAGINATION_ITEMS_PER_PAGE } from "@/constants";
import { cn } from "@/libs/utils";
import ProductCard from "@/presentation/components/ProductCard";
import ListNotFound from "@/presentation/components/ui/ListNotFound";
import SkeletonProductCard from "@/presentation/components/ui/SkeletonProductCard";

type ProductsListProps = {
	className?: string;
	isCategoryPage?: boolean;
	isLoading?: boolean;
	itemsPerPage?: number;
	products: ItemResponse[];
};

const ProductsList: React.FC<ProductsListProps> = ({
	products,
	isLoading,
	itemsPerPage,
	className,
	isCategoryPage,
}) => {
	if (products?.length === 0 && !isLoading && isCategoryPage) {
		return <ListNotFound />;
	}
	return (
		<div className={cn("grid grid-cols-3 gap-6 sm:grid-cols-2 sm:gap-4", className)}>
			{isLoading && products.length === 0
				? Array.from({ length: itemsPerPage || PAGINATION_ITEMS_PER_PAGE }, (_, index) => (
						<SkeletonProductCard key={index} />
					))
				: products?.map((product) => {
						return <ProductCard key={product?.id} product={product} />;
					})}
		</div>
	);
};

export default ProductsList;
