import type { PropertyGroupResponse } from "@/data/__generated__/types/generated";

import { useRef } from "react";

import { XPlitkaIcon } from "@RDN794312/xplitka-icons";

import useActions from "../FiltersAside/hooks";

import { FILTERS_ORDERING } from "@/constants";
import useScrollLock from "@/hooks/useScrollLock";
import { cn } from "@/libs/utils";
import { type SelectedFilters, ThemeColor } from "@/types";

import FilterBlock from "./components/FilterBlock";

type MobileFiltersProps = {
	closeFilters: () => void;
	filters?: PropertyGroupResponse[];
	handleSelectFilter: (value: string, isChecked: boolean, title: string) => void;
	isOpen: boolean;
	resetFilters: () => void;
	selectedFilters: SelectedFilters;
};

const MobileFilters: React.FC<MobileFiltersProps> = ({
	filters = [],
	selectedFilters,
	handleSelectFilter,
	resetFilters,
	isOpen,
	closeFilters,
}) => {
	const ref = useRef(null);
	useScrollLock(isOpen);
	const { sortedFilters } = useActions({ filters, selectedFilters });

	return (
		<div
			ref={ref}
			className={cn(
				"flex-col w-full fixed left-0 hidden overflow-y-auto z-[999] px-4 h-full py-6 top-0 bg-white sm:flex trasition duration-200 -translate-y-full",
				isOpen ? "translate-y-0" : ""
			)}
		>
			<div className="flex items-center justify-between">
				<p className="font-medium text-2xl">Фильтры</p>
				<button onClick={closeFilters} aria-label="Закрыть фильтры" type="button">
					<XPlitkaIcon name="cross" size={24} color={ThemeColor.accentGreen} />
				</button>
			</div>
			<div className="flex flex-col gap-[15px] my-6">
				{sortedFilters.map((filter) => {
					return (
						<FilterBlock
							key={filter.title}
							selectedFilters={selectedFilters}
							handleSelectFilter={handleSelectFilter}
							filter={filter}
							isDefaultExpanded={FILTERS_ORDERING[filter.title]?.defaultExpanded ?? false}
						/>
					);
				})}
			</div>
			<div className="flex flex-col gap-2">
				<button
					onClick={resetFilters}
					className="h-12 border-2 border-mediumGray rounded-xl w-full font-medium text-lg hover:bg-lightGlass transition"
					type="button"
					aria-label="Очистить фильтры"
				>
					Очистить фильтры
				</button>
			</div>
		</div>
	);
};

export default MobileFilters;
