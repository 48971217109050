import type { PropertyGroupResponse } from "@/data/__generated__/types/generated";
import type { SelectedFilters } from "@/types";

import { useEffect, useState } from "react";

import { XPlitkaIcon } from "@RDN794312/xplitka-icons";

import { FILTERS_DEFAULT_COUNT } from "@/constants";
import { determineCategory } from "@/helpers";
import Checkbox from "@/presentation/components/ui/Checkbox";
import { ThemeColor } from "@/types";

type FilterBlockProps = {
	filter: PropertyGroupResponse;
	handleSelectFilter: (value: string, isChecked: boolean, title: string) => void;
	isDefaultExpanded: boolean;
	selectedFilters: SelectedFilters;
};

const FilterBlock: React.FC<FilterBlockProps> = ({
	filter,
	handleSelectFilter,
	isDefaultExpanded,
	selectedFilters,
}) => {
	const categoryKey = determineCategory(filter.title);

	const sortedValues = [...filter.values].sort((a, b) => {
		const aChecked = selectedFilters[categoryKey]?.includes(a.slug);
		const bChecked = selectedFilters[categoryKey]?.includes(b.slug);
		return aChecked === bChecked ? 0 : aChecked ? -1 : 1;
	});

	const [isExpanded, setIsExpanded] = useState(isDefaultExpanded);
	const [filtersCount, setFiltersCount] = useState(FILTERS_DEFAULT_COUNT);

	const allFiltersCount = filter?.values?.length;
	const isOpened = filtersCount === allFiltersCount;

	const toggleOpenFilters = () => {
		setFiltersCount(isOpened ? FILTERS_DEFAULT_COUNT : allFiltersCount);
	};

	const toggleExpand = () => setIsExpanded(!isExpanded);

	useEffect(() => {
		const isAnyFilterSelected = filter.values.some((value) => selectedFilters[categoryKey]?.includes(value.slug));
		setIsExpanded(isAnyFilterSelected || isDefaultExpanded);
	}, [filter.values, selectedFilters, categoryKey, isDefaultExpanded]);

	if (!filter) return null;

	return (
		<div>
			<button
				type="button"
				aria-label="Показать фильтры"
				onClick={toggleExpand}
				className="flex items-center justify-between font-medium w-full text-lg"
			>
				<span>{filter.title}</span>
				<XPlitkaIcon
					name={isExpanded ? "arrowSmallBottom" : "arrowSmallLeft"}
					color={isExpanded ? ThemeColor.accentGreen : ThemeColor.primaryBlack}
					size={24}
				/>
			</button>
			{isExpanded && (
				<div className="mt-2">
					{sortedValues.slice(0, filtersCount).map((value) => {
						const isChecked = selectedFilters[categoryKey]!.includes(value.slug);

						return (
							<Checkbox
								key={`${filter.title}_${value.slug}`}
								id={value.slug}
								label={value.title}
								value={value.slug}
								checked={isChecked}
								onChange={({ target }) => handleSelectFilter(target.value, target.checked, filter.title)}
							/>
						);
					})}
					{sortedValues.length > FILTERS_DEFAULT_COUNT && (
						<button onClick={toggleOpenFilters} type="button" className="font-medium">
							{isOpened ? "Скрыть" : "Посмотреть все"}
						</button>
					)}
				</div>
			)}
		</div>
	);
};

export default FilterBlock;
