import type { SelectedFilters } from "@/types";

import { PAGINATION_ITEMS_PER_PAGE } from "@/constants";
import api from "@/data/api";

type ApiListArgs = {
	categoryLft: number;
	categoryRgt: number;
	categoryUrl: string;
	isProducts?: boolean;
	queryLimit?: number;
	selectedFilters: SelectedFilters | null;
	ssrSelectedFilters: SelectedFilters | null;
};

export const useListApi = ({
	isProducts = false,
	categoryLft,
	categoryRgt,
	selectedFilters,
	ssrSelectedFilters,
	queryLimit,
}: ApiListArgs) => {
	const variables = {
		limit: queryLimit || PAGINATION_ITEMS_PER_PAGE,
		categoryLft,
		categoryRgt,
		filters: ssrSelectedFilters || selectedFilters,
	};

	const { collection, products } = api;

	const {
		getCollections: { data: collectionsData, loading: collectionsLoading, fetchMore: fetchMoreCollections },
		getProducts: { data: productsData, loading: productsLoading, fetchMore: fetchMoreProducts },
		// getProperties: { data: filtersData, loading: filtersLoading },
	} = {
		getCollections: collection.Collections(
			{
				input: variables,
			},
			{ notifyOnNetworkStatusChange: true }
		),
		getProducts: products.Items(
			{
				input: variables,
			},
			{ notifyOnNetworkStatusChange: true }
		),
		// getProperties: properties.CategoryFilters(
		//   {
		//     input: { categoryLft, categoryRgt, categoryUrl },
		//   },
		//   { notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only' }
		// ),
	};

	const handleFetchMore = () => {
		const nextPage = isProducts ? productsData?.items.nextPage : collectionsData?.collections.nextPage;

		if (!nextPage) return;
		const fetchMoreFn = isProducts ? fetchMoreProducts : fetchMoreCollections;

		fetchMoreFn({
			variables: {
				input: {
					...variables,
					offset: nextPage,
				},
			},
		});
	};

	return {
		collectionsData,
		collectionsLoading,
		productsData,
		productsLoading,
		// filtersData,
		// filtersLoading,
		handleFetchMore,
	};
};
