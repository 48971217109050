import { sanitize } from "isomorphic-dompurify";

type CategoryDescriptionProps = {
	categoryDescription?: null | string;
};

const CategoryDescription: React.FC<CategoryDescriptionProps> = ({ categoryDescription }) => {
	if (!categoryDescription) return null;
	return (
		<div className="border-2 border-white rounded-3xl py-4 px-6 mt-12">
			<div dangerouslySetInnerHTML={{ __html: sanitize(categoryDescription) }} className="html-description" />
		</div>
	);
};

export default CategoryDescription;
